// 
// input-group.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

.input-borderless input{
  border:none;
}