// 
// text-truncation.scss
// Extended from Bootstrap
// 

//
// Text truncation two lines
//

.text-truncate-2{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
 }